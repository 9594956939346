body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Inter', sans-serif;
  height: 100vh;
  min-width: 1440px;
  background-color: rgba(var(--v-bg-page, 240, 243, 247, 1));
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: black;
}

img {
  vertical-align: middle;
}
