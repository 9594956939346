html {
  font-size: 16px;
  line-height: inherit;
}

vega-left-nav {
  width: 340px;
}

.vega-left-nav-content.open {
  margin-left: 340px !important;
}

.logo {
  padding: 8px 24px 48px;
}

#table-container tr {
  height: 64px;
}

.logo img {
  height: 40px;
}

#menuSearch {
  margin-bottom: 32px;
}

.font-weight-bold {
  font-weight: bold;
}

.display-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}
